<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  notificationMethods
} from "@/state/helpers";

import {
  required,
} from "vuelidate/lib/validators";

import { conditionService } from '../../../services/condition.service';
const initForm = {id:null, parameter: null, createdBy: JSON.parse(localStorage.getItem('user')).id,};

export default {
    page: {
    title: "System Configuration",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
},
  data() {
    return {
      title: "Business Conditions",
      items: [
        {
          text: "System",
          active: true
        },
        {
          text: "Business Conditions",
          active: true
        }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      businessCondition:{description:null,id:null,parament:null},
      currencyList: [
                {
                    currencyType:"zwl",
                    currencyName: "ZIMBABWEAN DOLLAR"
                },
                {
                    currencyType:"usd",
                    currencyName: "USD"
                },
                {
                    currencyType:"rand",
                    currencyName: "RAND"
                },
                {
                    currencyType:"pound",
                    currencyName: "BRITISH POUND"
                },
                {
                    currencyType:"euro",
                    currencyName: "EURO"
                }
      ],

      fields: [
        { key: "id", sortable: true },
        { key: "description", sortable: true },
        { key: "parameter", sortable: true },
        { key: "actions"}
      ],

      form: {
        id:null,
        parameter: null,
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      submitted: false,
      submitform: false,
      submit: false,
      showform: false,
      updateSuccess:false,
      updateFailed:false,
      responseMessage:null
    };
  },

  validations: {
    form: {
      parameter: { required }
    },
  },

  created() {
    this.laodAllConditions()
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    storeState(condition){
      this.businessCondition = condition.item
    },

    updateCondition() {

      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submit = false;
        return;
      } else {
          const {form} = this;
          form.id = this.businessCondition.id;

          const shopForm = {
                "operation" : "shopCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          };
          
          conditionService.updateBusinessCondition(shopForm).then(result=>{
            this.responseMessage = result.message
            if(result.status=="SUCCESS"){
              this.laodAllConditions()
              this.updateSuccess = true;
              this.submitted = false;
              this.form = Object.assign({}, initForm);
            } else {
              this.submitted = false;
              this.updateFailed = true;
            }
          });
      }
    },


    async laodAllConditions() {
        try {
        await conditionService.getAllConditions().then(response=>{
            if(response.responseBody.length>0){
                this.tableData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },
  },

};

</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <b-modal id="modal-standard" title="Update the business condition" title-class="font-18" hide-footer>
                          <h5  class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="updateFailed">
                              <i :class="'mdi mdi-block-helper mr-2'"></i>{{responseMessage}}
                          </h5>
                          <h5  class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="updateSuccess">
                              <i :class="'mdi mdi-check-all mr-2'"></i>{{responseMessage}}
                          </h5>
                            <form class="needs-registration" @submit.prevent="updateCondition">
                            
                                <div class="row">
                                  <div class="col-md-12" v-if="businessCondition.description!=='exchangeRateBaseCurrency'">
                                    <div class="form-group">
                                      <label for="validationCustom02">New {{businessCondition.description}} Value</label>
                                      <div>
                                        <input
                                          id="validationCustom02"
                                          v-model="form.parameter"
                                          type="text"
                                          class="form-control"
                                          placeholder="Enter Value"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12" v-if="businessCondition.description==='exchangeRateBaseCurrency'">
                                    <div class="form-group">
                                      <label>Select Base Currency</label>
                                      <select class="form-control" v-model="form.parameter" :class="{ 'is-invalid': submitted && $v.form.parameter.$error }">
                                          <option v-for="(currency, index) in currencyList" :value="currency.currencyType" :key="index">{{currency.currencyName}}</option>
                                      </select>
                                      <div v-if="submitted && $v.form.parameter.$error" class="invalid-feedback">
                                          <span v-if="!$v.form.parameter.required">This value is required.</span>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                          
                                <div class="spinner" v-if="submitted">
                                    <i class="ri-loader-line spin-icon"></i>
                                </div>
                  
                              <button v-if="!submitted" class="btn btn-primary" type="submit">Save changes</button>
                            </form>
                
            </b-modal>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
            
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(actions)="row">
                <a
                  @click="storeState(row)"
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >

                  <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>

                </a>
                <!-- <a
                  @click="row.toggleDetails"
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Disable"
                >
                  <i class="mdi mdi-cancel font-size-18"></i>
                </a> -->

              </template>
      </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout> 
</template>